import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import spartanShield from "../assets/branding/logo.png";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { useEffect } from "react";
import { Link } from "react-router-dom";

let html = document.querySelector("html");

const SideBar = () => {
  const closeSidebar = () => {
    html?.classList.remove("sidebar--open");
  };

  const showSidebar = () => {
    if (window.innerWidth > 991) return;

    html?.classList.add("sidebar--open");
  };

  const scrollToSection = (name: string) => {
    closeSidebar();
    if (name === "/") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });

      return;
    }

    let section = document.querySelector(`#${name}`);

    if (section !== null) {
      section.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  };

  //   function isInViewport(element) {
  //     const rect = element.getBoundingClientRect();
  //     return (
  //         rect.top >= 0 &&
  //         rect.left >= 0 &&
  //         rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
  //         rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  //     );
  // }

  useEffect(() => {
    if (window.innerWidth > 991) {
      showSidebar();
    } else {
      closeSidebar();
    }
  }, []);

  return (
    <>
      <div className="sidebar-toggle" onClick={showSidebar}>
        <FontAwesomeIcon icon={faBars} />
      </div>

      <div className="sidebar">
        <span className="sidebar__close" onClick={() => closeSidebar()}>
          &times;
        </span>
        <img
          className="sidebar__logo"
          src={spartanShield}
          alt="Stockport Spartans"
        />
        <div className="nav">
          <div className="nav__item" onClick={() => scrollToSection("/")}>
            Home
          </div>
          <div className="nav__item" onClick={() => scrollToSection("aboutUs")}>
            About
          </div>
          <div
            className="nav__item"
            onClick={() => scrollToSection("facilities")}
          >
            Facilities
          </div>
        </div>
        <div className="socials">
          <a
            href="https://www.instagram.com/stockportspartanswlc/"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faInstagram} />
          </a>
        </div>
        <Link to="members" className="button">
          Members Login
        </Link>
      </div>
    </>
  );
};

export default SideBar;
